@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .main-layout {
        /* @apply grid bg-grey-100 grid-cols-[18.75%_80%] min-h-full; */
        @apply relative flex bg-grey-100 min-w-full max-h-screen h-full overflow-hidden;
    }

    .auth-banner {
        @apply h-full w-full m-0 bg-auto bg-center relative;
    }

    .link {
        @apply text-purple hover:text-purple-200 underline;
    }

    input,
    select,
    .select {
        @apply px-3 py-2 rounded-xl bg-grey-100
    }

    .input {
        @apply w-full bg-white hover:shadow-purple;
    }

    .input-group {
        @apply rounded-xl bg-grey-100 hover:shadow-purple;
    }

    .btn {
        @apply w-full flex flex-nowrap justify-center items-center py-4 px-6 rounded-[8px] space-x-3;
    }

    .btn-primary {
        @apply bg-purple text-white
    }

    .btn-outlined {
        @apply bg-white text-purple border border-purple
    }

    .card {
        @apply bg-white rounded-xl border-none py-10 px-6 flex-grow w-full shadow-md text-left;
    }

    .section-header {
        @apply text-purple-300 font-semibold text-xl;
    }

    .card {
        @apply col-span-1 bg-white rounded-xl border-none w-full shadow-md grow;
    }

    .is-invalid {
        @apply border-[#dc3545];
    }
}

html,
body, #root {
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.custom-table {
    min-width: 900px;
    width: 100%;
    border-top: 1px solid #d3d1d1;
    white-space: nowrap;
    text-align: left;
}

.custom-table thead {
    padding-block: 1.5rem;
}

.custom-table thead tr,
.custom-table thead th {
    border-top: none;
    border-bottom: none !important;
    padding-top: 20px;
    color: #6b0be4;
    font-size: 1rem;
    font-weight: 600;
}

.custom-table tbody tr:not(.spacer) {
    border-radius: 7px;
    overflow: hidden;
    transition: 0.3s all ease;
    background-color: #fff;
}

.custom-table tbody tr:not(.spacer):hover,
.custom-table tbody tr:not(.no-data):hover {
    background-color: #f8f2ff;
    box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.1);
}

.custom-table tbody tr:not(.spacer) th,
.custom-table tbody tr:not(.spacer) td {
    color: #777;
    font-weight: 400;
    padding-bottom: 20px;
    padding-top: 20px;
    /* padding-right: 20px; */
    /* padding-left: 20px; */
    font-weight: 300;
    border: none;
}

.custom-table tbody tr:not(.spacer) td small {
    color: #b3b3b3;
    font-weight: 300;
}

.custom-table thead tr:not(.spacer) th:first-child,
.custom-table tbody tr:not(.spacer) td:first-child {
    padding-left: 20px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.custom-table thead tr:not(.spacer) th:last-child,
.custom-table tbody tr:not(.spacer) td:last-child {
    padding-right: 20px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}

.custom-table tbody tr:not(.spacer).spacer td {
    padding: 0 !important;
    height: 10px;
    border-radius: 0 !important;
    background: transparent !important;
}