#visitors-overview {
	 margin-bottom: 3.5rem;
}
 #visitors-overview .head {
	 display: flex;
	 justify-content: space-between;
	 align-items: center;
	 margin-bottom: 1.5rem;
}
 #visitors-overview .cards {
	 display: grid;
	 grid-template-columns: repeat(4, minmax(0, 1fr));
	 justify-content: space-between;
	 gap: 3.1rem;
}
 #visitors-overview .cards .card {
	 column-span: 1;
	 background-color: #fff;
	 box-shadow: 2px 8px 25px rgba(223, 224, 235, 0.15);
	 border-radius: 10px;
	 border: none;
	 padding: 2.5rem 1.5rem;
	 flex-grow: 1;
	 width: 100%;
}
 